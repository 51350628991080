<template>
    <div class="putInto">
        <div class="content-title ">收款人：<span>{{name}}</span></div>
        <div class="content-title ">开户行：<span>{{bankName}}</span></div>
        <div class="content-title ">卡号：<span>{{cardNo}}</span></div>
        <div class="content-title ">报销总金额：<span>{{reimburseMoney}}元</span></div>
        <div class="content-title ">成本中心：</div>
        <van-cell title="成本中心选择"  :value="stateText" @click="showState=true"/>
        <van-popup v-model="showState" round position="bottom">
            <van-picker title="成本中心" show-toolbar
                        :columns="costList"
                        @cancel="showState = false"
                        @confirm="changeStateName"
            />
        </van-popup>
            <div class="content-title ">报销事由:</div>
            <van-field  class="workNote"
                        v-model="reimburseTxt"
                        rows="1"
                        autosize
                        type="textarea"
                        placeholder="请输入报销事由（5-100字）"
                        maxlength="100"
            />
        <div class="button-one">
            <van-button type="primary" color="#3F7C53" @click="affirm">确 定</van-button>
        </div>

    </div>

</template>

<script>
    import common from '@/utils/common'
    import {getReimburseInfo,reimburse} from '@/utils/putInto';
    import {Toast} from "vant";
    export default {
        name: "reimburse.vue",
        data(){
            return{
                spNoList:[],
                name:'',
                cardNo:'',
                bankName:'',
                reimburseMoney:'',
                reimburseTxt:'',
                stateText:'请选择',
                stateValue:'',
                showState:false,
                costList:[
                    {
                        text:'请选择',
                        value:''
                    },
                    {
                        text:'郑州项目部',
                        value:'option-1723726853926'
                    },
                    {
                        text:'齐商项目部',
                        value:'option-1637047378978'
                    },
                    {
                        text:'中行项目部',
                        value:'option-1637047412670'
                    },
                    {
                        text:'湖州项目部',
                        value:'option-1639537887331'
                    },
                    {
                        text:'厦门项目部',
                        value:'option-1647336748217'
                    },
                    {
                        text:'承德项目部',
                        value:'option-1647336742935'
                    },
                    {
                        text:'东营项目部',
                        value:'option-1709095378900'
                    },
                    {
                        text:'农村零售业务部',
                        value:'option-1676354997661'
                    }
                ],
            }
        },
        created(){
            this.getReimburseInfo();
        },
        methods:{
            changeStateName(value){
                this.stateText = value.text;
                this.stateValue = value.value;
                this.showState = false;
            },
            getReimburseInfo(){
                this.spNoList = this.$route.params.data;
                console.log('spNoList',this.spNoList);
                getReimburseInfo({transferGoodsList:this.spNoList}).then(res=>{
                    console.log('返回数据',res);
                    if(res.code===200 && res.data.businessCode===200){
                        this.name= res.data.data.name;
                        this.cardNo= res.data.data.cardNo;
                        this.bankName= res.data.data.bankName;
                        this.reimburseMoney= res.data.data.reimburseMoney;
                    }
                })
            },
            affirm(){
                if(!this.stateValue){
                    this.$message.warning('请选择成本中心');
                    return;
                }
                if(!this.reimburseTxt){
                    this.$message.warning('请输入报销事由');
                    return;
                }
                if(this.reimburseTxt && (this.reimburseTxt.length < 5 || this.reimburseTxt.length > 100)){
                    this.$toast('报销事由字段长度为5-100')
                    return
                }
                let data = {
                    name:this.name,
                    cardNo:this.cardNo,
                    bankName:this.bankName,
                    reimburseMoney:this.reimburseMoney,
                    reimburseTxt:this.reimburseTxt,
                    spNoList:this.spNoList,
                    stateValue:this.stateValue,
                }
                this.$dialog.alert({
                    message: '确认发起报销？',
                    showCancelButton: true,
                    confirmButtonColor:'#3F7C53'
                }).then(() => {
                    console.log('data', data);
                    reimburse(data).then(res=>{
                        console.log('确认按钮', res);
                        if(res.code ===200 && res.data.businessCode === 200){
                            //this.$message.success('报销已提交企微发起审核!');
                            Toast("报销已提交企微发起审核!");
                            this.$router.go(-1);
                            common.setItem('active', 1);
                        }
                    })
                })
                    .catch(() => {
                    })
            }
        }
    }
</script>

<style lang="less">
    @import "../../style/putInto.less";
    .search-box{
        .van-cell{
            padding: 14px 15px ;
            border-bottom: 1px solid #F7F8F9;
        }
    }
    .content-title{
        margin: 15px 0.3rem;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        height: 15px;
        line-height: 16px;
        border-left:2px solid #3F7C53;
        padding-left: 4px;

    }
    .workNote{
        border-top: 1px solid #EEEEEE;
    }

</style>
